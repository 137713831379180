/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../../src";
import { ApiShippingServiceModel } from "../../../types";

/**
* send **GET** request to **\/Ecommerce/cart/shipping/available**
*/

export const listAvailableShippingServicesRequest = (ctx: RequestContext) => request<ApiShippingServiceModel[]>({
  method: "GET",
  path: encodeUri`/ecommerce/cart/shipping/available`,
  ctx
});
