import { ApiCurrency, ApiNodeOfCategoryDtoAndInteger, listCategoriesRequest, listCurrenciesRequest } from "@ecommerce/core/generated";
import { BaseRequestContext } from "@ecommerce/core/src";
import { GetStaticPropsContext, GetStaticPropsResult } from "next";
import { FC } from "react";

import { channelCode } from "./channelCode";

export type SharedProps = {
  _shared: {
    currencies: ApiCurrency[];
    menu: ApiNodeOfCategoryDtoAndInteger[];
  };
};

export type EcommercePage<T> = FC<T & SharedProps>;

export const ssgProps = <T>(
  gssp?: (ctx: GetStaticPropsContext & { ctx: BaseRequestContext }) => Promise<GetStaticPropsResult<T>>
) => async (ssgContext: GetStaticPropsContext): Promise<GetStaticPropsResult<T & SharedProps>> => {
  const ctx = statelessCtx(ssgContext.locale!);
  const [props, currencies, menu] = await Promise.all([
    gssp?.({ ...ssgContext, ctx }),
    listCurrenciesRequest(),
    listCategoriesRequest({}, "menu")
  ]);

  const _shared = {
    currencies,
    menu
  };

  if (!gssp) {
    return { props: { _shared } as any, revalidate: 10 };
  }

  if ("props" in props!) {
    return {
      revalidate: 10,
      ...props,
      props: {
        ...props.props,
        _shared
      }
    };
  } else {
    return {
      revalidate: 10,
      ...props!
    };
  }
};

export const statelessCtx = (locale: string): BaseRequestContext => ({
  channelCode: channelCode,
  locale: locale!,
  currency: defaultCurrencies[locale]
});

export const defaultCurrencies: Record<string, string> = {
  en: "eur",
  de: "eur",
  ru: "rub"
};
