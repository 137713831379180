/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../src";
import { ApiChangeShippingServiceRequest, ApiCartModel } from "../../types";

/**
* send **PUT** request to **\/Ecommerce/cart/shipping**
*/

export const changeShippingServiceRequest = (ctx: RequestContext, body: ApiChangeShippingServiceRequest) => request<ApiCartModel>({
  method: "PUT",
  path: encodeUri`/ecommerce/cart/shipping`,
  body,
  ctx
});

/**
* send **DELETE** request to **\/Ecommerce/cart/shipping**
*/

export const removeShippingFromCartRequest = (ctx: RequestContext) => request<ApiCartModel>({
  method: "DELETE",
  path: encodeUri`/ecommerce/cart/shipping`,
  ctx
});
