/* eslint-disable max-len */
import { encodeUri, request } from "../../../../src";
import { ApiAddressModel } from "../../../types";

/**
* send **GET** request to **\/Ecommerce/user/customer-addresses/{addressId}**
*/

export const getAddressRequest = (ctx: {} = {}, addressId: number) => request<ApiAddressModel>({
  method: "GET",
  path: encodeUri`/ecommerce/user/customer-addresses/${addressId}`,
  ctx
});

/**
* send **DELETE** request to **\/Ecommerce/user/customer-addresses/{addressId}**
*/

export const deleteAddressRequest = (ctx: {} = {}, addressId: number) => request<unknown>({
  method: "DELETE",
  path: encodeUri`/ecommerce/user/customer-addresses/${addressId}`,
  ctx
});
