/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../src";
import { ApiPlaceOrderRequest, ApiOrderDetailModel } from "../../types";

/**
* send **PUT** request to **\/Ecommerce/cart/note**
*/

export const placeOrderRequest = (ctx: RequestContext, body: ApiPlaceOrderRequest) => request<ApiOrderDetailModel>({
  method: "PUT",
  path: encodeUri`/ecommerce/cart/note`,
  body,
  ctx
});
