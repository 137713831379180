/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";
import { ApiRegisterUserRequest } from "../../types";

/**
* send **POST** request to **\/Ecommerce/auth/register**
*/

export const registerUserRequest = (ctx: {} = {}, body: ApiRegisterUserRequest) => request<string>({
  method: "POST",
  path: encodeUri`/ecommerce/auth/register`,
  body,
  ctx
});
