/* eslint-disable max-len */
import { encodeUri, request } from "../../../../src";
import { ApiQueryProductsResult } from "../../../types";

/**
* send **GET** request to **\/Ecommerce/product/query/{query}**
*/

export const queryProductsRequest = (ctx: {} = {}, query: string) => request<ApiQueryProductsResult[]>({
  method: "GET",
  path: encodeUri`/ecommerce/product/query/${query}`,
  ctx
});
