/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";
import { ApiAuthModel, ApiWebUserModel } from "../../types";

/**
* send **PUT** request to **\/Ecommerce/user/update**
*/

export const updateUserRequest = (ctx: {} = {}, body: ApiAuthModel) => request<ApiWebUserModel>({
  method: "PUT",
  path: encodeUri`/ecommerce/user/update`,
  body,
  ctx
});
