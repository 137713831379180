import { parse } from "content-range";

import { joinPath } from "./utils/joinPath";
import { makeQuery } from "./utils/makeQuery";

export interface ApiRequestOptions {
  method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH";
  path: string;
  body?: any;
  contentType?: "application/json" | string;

  ctx: RequestContext | BaseRequestContext | {};
}

export interface ContentRange {
  unit?: string;
  start?: number | null;
  end?: number | null;
  size?: number | null;

  perPage: number;
  page: number;
}

export type RequestMeta = {
  headers: Record<string, string>;
};

export type PaginationResponse<T> = {
  items: T;
  range: ContentRange;
};

export interface PaginationRequest {
  page?: number;
  perPage?: number;
}

export interface BaseRequestContext {
  locale: string;
  currency: string;
  channelCode: string;
}

export interface RequestContext extends BaseRequestContext {
  customer?: number;
  cartGuid?: string;
}

export const apiUrl = process.env.NEXT_PUBLIC_API_URL;

interface Request {
  <T>(options: ApiRequestOptions): Promise<WithMeta<T>>;
  <T>(options: ApiRequestOptions, pagination: PaginationRequest): Promise<PaginationResponse<WithMeta<T>>>;
}

export const request: Request = (({ ctx, ...options }: any, pagination: any) => _request({ ctx, ...options }, pagination)) as any;

const _request = async <T>(
  { ctx, ...options }: ApiRequestOptions,
  pagination?: PaginationRequest
) => {
  const contentType = options.contentType ?? "application/json";

  if (!ctx) throw new Error("Request context was not provided, you can pass it directly or with <RequestContext> prodider.");

  try {
    const p = getPath(options.path, pagination);

    const headers = new Headers({ "Content-Type": contentType });
    if ("locale" in ctx) headers.append("Locale", ctx.locale);
    if ("currency" in ctx) headers.append("CurrencyCode", ctx.currency);
    if ("channelCode" in ctx) headers.append("ChannelCode", ctx.channelCode);
    if ("customer" in ctx && ctx.customer) headers.append("Customer", `${ctx.customer}`);
    if ("cartGuid" in ctx && ctx.cartGuid) headers.append("CartGuid", ctx.cartGuid);

    process.env.VERBOSE === "1" && console.info(p, JSON.stringify(headers));

    const response = await fetch(p, {
      method: options.method,
      headers,
      body: getRequestBody({ ...options, contentType }),
      credentials: "include"
    });

    const data = await getResponseBody<T>(response);

    if (pagination) {
      if (response.status === 404) {
        return {
          range: { page: pagination.page ?? 1, perPage: pagination.perPage ?? 50 },
          items: data
        };
      }

      const contentRange = response.headers.get("Content-Range");
      const range = contentRange ? parse(contentRange) : null;

      if (!range) {
        return Promise.reject(
          new Error(`Header Content-Range not exist in response from: ${response.url} (${response.status})`)
        );
      }

      return response.ok
        ? Promise.resolve<PaginationResponse<T>>({
          range: { ...range, page: pagination.page ?? 1, perPage: pagination.perPage ?? 50 },
          items: data
        })
        : Promise.reject(new ResponseError(
          response.status,
          response.statusText,
          data && typeof data === "object" && "errors" in data
            ? (data as any).errors
            : undefined
        ));
    }

    return response.ok
      ? data
      : Promise.reject(new ResponseError(
        response.status,
        response.statusText,
        data && typeof data === "object" && "errors" in data
          ? (data as any).errors
          : undefined
      ));
  } catch (e) {
    return Promise.reject(new ResponseError(500, "Unknown error", undefined, e as any));
  }
};

const getPath = (path: string, pagination?: PaginationRequest) => {
  if (!apiUrl) {
    throw new Error("Environment variable API_URL not set!");
  }

  const paginationUri = pagination
    ? `${path.includes("?") ? "&" : "?"}${makeQuery("page", "perPage")(pagination.page, pagination.perPage)}&`
    : "";

  return joinPath(apiUrl, path + paginationUri);
};

export const getRequestBody = (options: Omit<ApiRequestOptions, "ctx">) =>
  options.contentType?.includes("json") ? JSON.stringify(options.body) : options.body;

export type WithMeta<T> = T & {
  $meta: RequestMeta;
};

export const getResponseBody = async <T>(response: Response): Promise<WithMeta<T>> => {
  const body = response.headers.get("Content-Type")?.includes("json") ? await response.json() : await response.text();

  typeof body === "object" && Object.defineProperty(body, "$meta", {
    enumerable: false,
    configurable: false,
    writable: false,
    value: {
      headers: Object.fromEntries(response.headers.entries())
    }
  });

  return body;
};

export class ResponseError extends Error {
  status: number;
  title: string;
  errors?: Errors;

  public constructor (status: number, title: string, errors?: Errors, cause?: Error) {
    super(title);
    this.status = status;
    this.title = title;
    this.errors = errors;
    this.cause = cause;
  }
}

type Errors = {
  [P in string]?: string | Errors;
};
