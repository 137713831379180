/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../src";
import { ApiAddAddressToCartRequest, ApiCartModel } from "../../types";

/**
* send **PUT** request to **\/Ecommerce/cart/address**
*/

export const addAddressToCartRequest = (ctx: RequestContext, body: ApiAddAddressToCartRequest) => request<ApiCartModel>({
  method: "PUT",
  path: encodeUri`/ecommerce/cart/address`,
  body,
  ctx
});
