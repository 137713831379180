/* eslint-disable max-len */
import { encodeUri, request } from "../../../../src";
import { ApiAddressModel } from "../../../types";

/**
* send **GET** request to **\/Ecommerce/customer/{id}/delivery-address**
*/

export const listDeliveryAddressesRequest = (ctx: {} = {}, id: number) => request<ApiAddressModel[]>({
  method: "GET",
  path: encodeUri`/ecommerce/customer/${id}/delivery-address`,
  ctx
});
