/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";
import { ApiUserPreLoginRequest } from "../../types";

/**
* send **POST** request to **\/Ecommerce/auth/pre-login**
*/

export const preLoginUserRequest = (ctx: {} = {}, body: ApiUserPreLoginRequest) => request<string>({
  method: "POST",
  path: encodeUri`/ecommerce/auth/pre-login`,
  body,
  ctx
});
