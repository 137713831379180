type QueryValue = string | number | boolean | undefined;

export const makeQuery = (...keys: string[]) => (...query: (QueryValue | QueryValue[])[]): string =>
  keys
    .map((k, i) => {
      const v = query[i];
      return Array.isArray(v)
        ? v.map(encodeValue(k))
        : encodeValue(k)(v);
    })
    .flat()
    .filter(Boolean)
    .join("&");

const encodeValue = (k: string) => (v: QueryValue) =>
  v && `${k}=${encodeURIComponent(String(v))}`;

export const joinQueries = (...queries: string[]) =>
  queries.filter(Boolean).join("&");
