/* eslint-disable max-len */
import { encodeUri, request } from "../../../../src";
import { ApiProductVariantWithProductModel } from "../../../types";

/**
* send **GET** request to **\/Ecommerce/product/search/{searchedString}**
*/

export const searchProductsRequest = (ctx: {} = {}, searchedString: string) => request<ApiProductVariantWithProductModel[]>({
  method: "GET",
  path: encodeUri`/ecommerce/product/search/${searchedString}`,
  ctx
});
