/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../src";
import { ApiCartModel } from "../types";

/**
* send **GET** request to **\/Ecommerce/cart**
*/

export const getCartRequest = (ctx: RequestContext) => request<ApiCartModel>({
  method: "GET",
  path: encodeUri`/ecommerce/cart`,
  ctx
});
