import { createContext, useContext } from "react";
import useSWR, { SWRConfiguration } from "swr";

import { RequestContext, BaseRequestContext } from "./request";

export const useRequest = <Ctx extends {}, Params extends any[], T>(
  key: Params | null,
  fetcher: ((ctx: Ctx, ...p: Params) => Promise<T>),
  { ctx, ...config }: Partial<SWRConfiguration<T>> & { ctx?: Ctx } = {}
) => {
  const contextCtx = useRequestContext();
  return useSWR<T>(key !== null ? [fetcher, ctx ?? contextCtx, ...key] : null, swrFetcher, config);
};

export const useRequestContext = () => useContext(_RequestCtx);
const _RequestCtx = createContext<BaseRequestContext | RequestContext | undefined>(undefined);
export const RequestCtxProvider = _RequestCtx.Provider;

const swrFetcher = <Ctx extends {}, Params extends any[], T>(
  [fetcher, ctx, ...args]: [((ctx: Ctx, ...p: Params) => Promise<T>), Ctx, ...Params]
) => fetcher(ctx, ...args);
