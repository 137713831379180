/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";

/**
* send **POST** request to **\/Ecommerce/auth/logout**
*/

export const logoutUserRequest = (ctx: {} = {}) => request<string>({
  method: "POST",
  path: encodeUri`/ecommerce/auth/logout`,
  ctx
});
