import { getCartRequest, getCurrentUserRequest, loginUserRequest, logoutUserRequest, preLoginUserRequest } from "@ecommerce/core/generated";
import { RequestCtxProvider, useRequest } from "@ecommerce/core/src";
import { useModal } from "anolis-ui";
import { useRouter } from "next/router";
import { FC, useEffect, useMemo } from "react";
import { mutate } from "swr";
import { channelCode } from "utils/channelCode";
import { SharedProps } from "utils/ssgProps";
import { useEvent } from "utils/useEvent";

import { CartContext, GhostCart, ICartContext, ISharedPropsContext, IUserContext, SharedPropsContext, UserContext } from "./contexts";
import { PanicModal } from "./PanicModal";
import { useAppStateReducer } from "./useAppStateReducer";

const LOCAL_CARTGUID = "merch-cartGuid";
const LOCAL_USERID = "merch-userId";

export const AppContextProvider: FC<SharedProps> = ({ children, _shared }) => {
  const { locale } = useRouter();

  const [state, dispatch] = useAppStateReducer(locale!);
  const [openPanic] = useModal(PanicModal);

  // If anything unexpectedly fails, erase all user state
  const panic = useEvent(async (e?: any) => {
    localStorage.removeItem(LOCAL_CARTGUID);
    await logoutUserRequest({ ctx: state.ctx }).catch(() => null);
    dispatch([{ type: "clearUser" }, { type: "clearCart" }]);
    await mutate(_ => true);
    openPanic({ error: e });
    // Goodbye :(
  });

  // Start fetching cart & user info
  useEffect(() => {
    const authorized = async () => {
      const user = await getCurrentUserRequest({ ctx: state.ctx }).catch(() => {
        localStorage.removeItem(LOCAL_USERID);
        return null;
      });

      if (user === null) return unauthorized();

      dispatch({ type: "setUser", user });

      if (user.cartGuid) {
        dispatch({ type: "setCart", cart: await getCartRequest({ ...state.ctx, cartGuid: user.cartGuid }) });
      } else {
        localStorage.removeItem(LOCAL_CARTGUID);
        dispatch({ type: "clearCart" });
      }
    };

    const unauthorized = async () => {
      dispatch({ type: "clearUser" });
      const cartGuid = localStorage.getItem(LOCAL_CARTGUID);
      if (cartGuid) {
        const cart = await getCartRequest({ ...state.ctx, cartGuid }).catch(() => null);
        if (cart) {
          localStorage.setItem(LOCAL_CARTGUID, cart.guid!);
          dispatch({ type: "setCart", cart });
        } else {
          localStorage.removeItem(LOCAL_CARTGUID);
          dispatch({ type: "clearCart" });
        }
      } else {
        dispatch({ type: "clearCart" });
      }
    };

    // ensure that the data fetching only starts once
    if (!state.userLoadingStarted) {
      dispatch({ type: "startUserLoading" });
      const login = localStorage.getItem(LOCAL_USERID);
      login
        ? authorized().catch(panic)
        : unauthorized().catch(panic);
    }
  }, [dispatch, panic, state.ctx, state.user.data, state.userLoadingStarted]);

  const sharedPropsCtx = useMemo<ISharedPropsContext>(() => ({
    _shared,
    currency: _shared.currencies.find(c => c.code === state.ctx.currency)!,
    setCurrency: () => {}
  }), [_shared, state.ctx.currency]);

  const userCtx = useMemo<IUserContext>(() => ({
    user: state.user.data ?? undefined,
    isValidating: state.user.isValidating,
    loggedIn: !!state.user.data,

    mutateUser: async (d) => {
      const user = await d ?? await getCurrentUserRequest(state.ctx);
      localStorage.setItem(LOCAL_USERID, `${user.user!.id!}`);
      dispatch({ type: "setUser", user });
      return user;
    },
    login: async (username, password) => {
      const user = await loginUserRequest(state.ctx, { username, password });
      localStorage.setItem(LOCAL_USERID, `${user.id!}`);
      dispatch({ type: "setUser", user });
      if (user.cartGuid) {
        try {
          const cart = await getCartRequest({ ...state.ctx, cartGuid: user.cartGuid });
          localStorage.removeItem(LOCAL_CARTGUID);
          dispatch({ type: "setCart", cart });
          await mutate(_ => true);
        } catch (e) {
          await panic(e);
          throw e;
        }
      }
      return user;
    },
    prelogin: async (username) => preLoginUserRequest(state.ctx, { username, url: setPasswordUrl() }),
    logout: async () => {
      localStorage.removeItem(LOCAL_CARTGUID);
      localStorage.removeItem(LOCAL_USERID);
      await logoutUserRequest({ ctx: state.ctx });
      await mutate(_ => true);
      dispatch([{ type: "clearCart" }, { type: "clearUser" }]);
    }
  }), [dispatch, panic, state.ctx, state.user.data, state.user.isValidating]);

  const cartCtx = useMemo<ICartContext>(() => ({
    cart: state.cart.data ?? ghostCart,
    isValidating: state.cart.isValidating,
    mutateCart: async (d, clear = false) => {
      dispatch({ type: "startCartRevalidate", clear });
      try {
        const c = await d ?? await getCartRequest(state.ctx);
        localStorage.setItem(LOCAL_CARTGUID, c.guid!);
        dispatch({ type: "setCart", cart: c! });
        return c;
      } catch (e) {
        dispatch({ type: "cartRevalidateError" });
        throw e;
      }
    },
    clearCart: async () => {
      localStorage.removeItem(LOCAL_CARTGUID);
      dispatch({ type: "clearCart" });
    }
  }), [dispatch, state.cart, state.ctx]);

  // Take the advantage of SWR's built in revalidation, but revalidate only if the cart & user were already fetch
  useRequest(state.cart.data !== undefined ? [] : null, getCartRequest, {
    onSuccess: x => cartCtx.mutateCart(x),
    onError: () => state.cart.data && cartCtx.clearCart(),
    ctx: state.ctx,
    errorRetryCount: 0
  });

  useRequest(state.user.data !== undefined ? [] : null, getCurrentUserRequest, {
    onSuccess: x => userCtx.mutateUser(x),
    onError: async () => {
      if (state.user.data) {
        localStorage.removeItem(LOCAL_CARTGUID);
        localStorage.removeItem(LOCAL_USERID);
        await mutate(_ => true);
        dispatch([{ type: "clearCart" }, { type: "clearUser" }]);
      }
    },
    ctx: state.ctx,
    errorRetryCount: 0
  });

  return (
    <RequestCtxProvider value={state.ctx}>
      <SharedPropsContext.Provider value={sharedPropsCtx}>
        <UserContext.Provider value={userCtx}>
          <CartContext.Provider value={cartCtx}>
            {children}
          </CartContext.Provider>
        </UserContext.Provider>
      </SharedPropsContext.Provider>
    </RequestCtxProvider>
  );
};

const ghostCart: GhostCart = {
  _ghostCart: true,
  channelCode,
  items: [],
  billingAddress: {
    type: "billing",
    contactTitle: "unknown",
    street: "",
    apartment: "",
    zip: "",
    city: "",
    countryCode: "",
    contactFullName: "",
    contactEmail: "",
    contactPhone: "",
    contactPhonePrefix: ""
  },
  deliveryAddress: {
    type: "delivery",
    contactTitle: "unknown",
    street: "",
    apartment: "",
    zip: "",
    city: "",
    countryCode: "",
    contactFullName: "",
    contactEmail: "",
    contactPhone: "",
    contactPhonePrefix: ""
  }
};

const setPasswordUrl = () => {
  const url = new URL(window.location.href);
  url.pathname = "/account/set-password";
  return url.href;
};
