/* eslint-disable max-len */
import { encodeUri, request, makeQuery } from "../../../../src";
import { ApiGetProductByCategorySlugResult } from "../../../types";

export interface ListProductsByCategorySlugOptions {
  page?: number;
  perPage?: number;
  sortBy?: "default" | "price" | "name" | "id" | "createdAt" | "bestSellers";
  dir?: "asc" | "desc";
  sortByLocale?: string;
}

/**
* send **GET** request to **\/Ecommerce/product/category/{slug}**
*/

export const listProductsByCategorySlugRequest = (ctx: {} = {}, slug: string, options: ListProductsByCategorySlugOptions) => request<ApiGetProductByCategorySlugResult>({
  method: "GET",
  path: encodeUri`/ecommerce/product/category/${slug}?` + makeQuery("sortBy", "dir", "sortByLocale")(options.sortBy, options.dir, options.sortByLocale),
  ctx
}, {
  page: options.page,
  perPage: options.perPage
});
