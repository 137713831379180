/* eslint-disable max-len */
import { encodeUri, request } from "../../../../src";

/**
* send **GET** request to **\/Ecommerce/auth/verify-email/{verifyEmailToken}**
*/

export const verifyEmailRequest = (ctx: {} = {}, verifyEmailToken: string) => request<boolean>({
  method: "GET",
  path: encodeUri`/ecommerce/auth/verify-email/${verifyEmailToken}`,
  ctx
});
