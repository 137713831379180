/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../src";
import { ApiAddSaleToCartRequest, ApiCartModel } from "../../types";

/**
* send **PUT** request to **\/Ecommerce/sale/cart**
*/

export const addSaleToCartRequest = (ctx: RequestContext, body: ApiAddSaleToCartRequest) => request<ApiCartModel>({
  method: "PUT",
  path: encodeUri`/ecommerce/sale/cart`,
  body,
  ctx
});
