import { x } from "@xstyled/emotion";
import { Button, TextLink } from "anolis-ui";
import Link from "next/link";
import { parseCookies, setCookie } from "nookies";
import { FC, Fragment, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

const CookieBar: FC = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!parseCookies()["cookie-consent"]) {
      setOpen(true);
    }
  }, [isOpen]);

  const handleAllow = () => {
    setCookie(null, "cookie-consent", "allowed");
    setOpen(false);
  };

  const handleDeny = () => {
    setCookie(null, "cookie-consent", "denied");
    setOpen(false);
  };

  return (
    <Fragment>
      {isOpen && (
        <x.div
          position="fixed"
          zIndex={99}
          backgroundColor="black"
          w="100%"
          left={0}
          bottom={0}
          py={{ _: "1rem", sm: "2rem" }}
          px={{ _: "1rem", sm: "3rem" }}
        >
          <x.p
            color="white"
            fontSize="0.8rem"
            pb={{ _: "0.5rem", sm: "1rem" }}
          >
            <b>
              <FormattedMessage id="cookiebar.title" defaultMessage="ROBE Lighting uses cookies." />
              {" "}
            </b>
            <FormattedMessage
              id="cookiebar.description"
              defaultMessage="We use cookies to personalize content and ads and to analyse out traffic.
            Please see &quot;More about cookies&quot; to learn more about how they are used on our website.
            Click &quot;I agree&quot; to activate them or &quot;Close and just use cookies not requiring consent&quot;
            if you do not want them. You can change your settings on &quot;Cookies&quot; in the footer at any time."
            />
          </x.p>
          <x.div display="grid" gap={4} gridTemplateColumns={2}>
            <x.div>
              <Button textTransform="uppercase" onClick={handleAllow}>
                {formatMessage(content.button)}
              </Button>
              <Link href="/static/cookies">
                <a
                  target="_blank"
                >
                  <TextLink color="robe" ml={{ _: "0.5 rem", sm: "1rem" }}>
                    <FormattedMessage id="cookiebar.aboutCookiesLink" defaultMessage="More about cookies" />
                  </TextLink>
                </a>
              </Link>
            </x.div>
            <x.div textAlign="right" pt="1rem">
              <TextLink color="gray" textDecoration="underline" onClick={handleDeny}>
                <FormattedMessage id="cookiebar.rejectCookiesLink" defaultMessage="Close and just use cookies not requiring consent" />
              </TextLink>
            </x.div>
          </x.div>
        </x.div>
      )}
    </Fragment>
  );
};

export default CookieBar;

const content = defineMessages({
  button: {
    id: "cookiebar.button",
    defaultMessage: "I agree"
  }
});
