/* eslint-disable max-len */
import { encodeUri, request } from "../../../../../src";

/**
* send **GET** request to **\/Ecommerce/auth/reset-password/check-token/{lostPasswordResetToken}**
*/

export const checkResetPasswordTokenRequest = (ctx: {} = {}, lostPasswordResetToken: string) => request<boolean>({
  method: "GET",
  path: encodeUri`/ecommerce/auth/reset-password/check-token/${lostPasswordResetToken}`,
  ctx
});
