import { ApiCartModel, ApiUserCustomerModel } from "@ecommerce/core/generated";
import { BaseRequestContext, RequestContext } from "@ecommerce/core/src";
import { Reducer, useReducer } from "react";
import { statelessCtx } from "utils/ssgProps";

interface AppState {
  ctx: BaseRequestContext | RequestContext;

  // Undefined means not loaded, null means unable to load
  cart: AppStateResponse<ApiCartModel | null>;
  user: AppStateResponse<ApiUserCustomerModel | null>;

  userLoadingStarted: boolean;
}

type AppStateResponse<T> = { data: T | undefined; isValidating: boolean };

type AppAction =
  | { type: "setUser"; user: ApiUserCustomerModel }
  | { type: "clearUser" }
  | { type: "startCartRevalidate"; clear?: boolean }
  | { type: "cartRevalidateError" }
  | { type: "setCart"; cart: ApiCartModel }
  | { type: "clearCart" }
  | { type: "startUserLoading" };

type AppActions = AppAction | AppAction[];

export const useAppStateReducer = (locale: string) => useReducer(appReducer, locale, appInit);

const appReducer: Reducer<AppState, AppActions> = (_state, _action) => {
  const actions = [_action].flat();

  return actions.reduce((state, action) => {
    switch (action.type) {
      case "startUserLoading":
        return {
          ...state,
          userLoadingStarted: true
        };
      case "setUser": {
        return {
          ...state,
          user: stopRevalidate(action.user),
          ctx: {
            ...state.ctx,
            customer: action.user.customer?.id
          }
        };
      }
      case "clearUser": {
        const { customer, ...c } = { ...state.ctx, customer: undefined };
        return { ...state, user: stopRevalidate(null), ctx: c };
      }
      case "setCart": {
        return {
          ...state,
          cart: stopRevalidate(action.cart),
          ctx: {
            ...state.ctx,
            cartGuid: action.cart.guid
          }
        };
      }
      case "startCartRevalidate":
        return {
          ...state,
          cart: revalidateResponse(state.cart.data, action.clear)
        };
      case "cartRevalidateError":
        return {
          ...state,
          cart: state.cart.data !== undefined ? stopRevalidate(state.cart.data) : state.cart
        };
      case "clearCart": {
        const { cartGuid, ...c } = { ...state.ctx, cartGuid: undefined };
        return {
          ...state,
          cart: stopRevalidate(null),
          ctx: c
        };
      }
    }
    return {} as never;
  }, _state);
};

const appInit = (l: string): AppState => ({
  ctx: statelessCtx(l),
  cart: { data: undefined, isValidating: true },
  user: { data: undefined, isValidating: true },
  userLoadingStarted: false
});

const logAction = (x: (state: AppState, action: AppAction) => AppState) => (state: AppState, action: AppAction) => {
  const s = x(state, action);
  console.info(action, s);
  return s;
};

const stopRevalidate = <T>(data: T): AppStateResponse<T> =>
  ({ data, isValidating: false });
const revalidateResponse = <T>(data?: T, clear: boolean = false): AppStateResponse<T> =>
  ({ data: clear ? undefined : data, isValidating: true });
