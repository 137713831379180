import { ApiCartModel, ApiCurrency, ApiUserCustomerModel, listTaxRatesRequest } from "@ecommerce/core/generated";
import { useRequest } from "@ecommerce/core/src";
import { createContext, useContext } from "react";
import { SharedProps } from "utils/ssgProps";

export interface ISharedPropsContext {
  _shared: SharedProps["_shared"];

  currency: ApiCurrency;
  setCurrency: (c: string) => unknown;
}
export const SharedPropsContext = createContext<ISharedPropsContext>(null!);
export const useSharedProps = () => useContext(SharedPropsContext);

export interface IUserContext {
  user?: ApiUserCustomerModel;
  isValidating: boolean;
  loggedIn: boolean;
  mutateUser: (data?: ApiUserCustomerModel | Promise<ApiUserCustomerModel>) => Promise<ApiUserCustomerModel>;

  login: (username: string, password: string) => Promise<ApiUserCustomerModel>;
  prelogin: (username: string) => Promise<unknown>;
  logout: () => Promise<unknown>;
}
export const UserContext = createContext<IUserContext>(null!);
export const useUser = () => useContext(UserContext);

export type GhostCart =
  Omit<ApiCartModel, "priceSummary" | "priceSummaryWithoutSale" | "createdAt" | "taxes" | "shippingService" | "paymentMethod"> & {
    _ghostCart: true;
    priceSummary?: undefined;
    priceSummaryWithoutSale?: undefined;
    createdAt?: undefined;
    taxes?: undefined;
    shippingService?: undefined;
    paymentMethod?: undefined;
  };

export interface ICartContext {
  cart: ApiCartModel | GhostCart;
  isValidating: boolean;
  mutateCart: (data?: ApiCartModel | Promise<ApiCartModel>, clear?: boolean) => Promise<ApiCartModel>;
  clearCart: () => unknown;
}
export const CartContext = createContext<ICartContext>(null!);
export const useCart = () => useContext(CartContext);

export const useTaxRate = (enable?: boolean) => {
  const { user, isValidating } = useUser();
  const { data } = useRequest(enable && !isValidating && !user ? [] : null, listTaxRatesRequest);

  return data?.[0].rate ?? user?.taxRate;
};

export const isGhostCart = (cart: GhostCart | ApiCartModel): cart is GhostCart => "_ghostCart" in cart;
