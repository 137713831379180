import {
  buttonTheme,
  cardTheme,
  containerTheme,
  createTheme,
  inputTheme, listTheme, modalTheme,
  tagTheme,
  textLinkTheme,
  typographyTheme,
  dropMenuTheme
} from "anolis-ui";

export const theme = createTheme({
  ...typographyTheme({
    baseStyle: {
      fontFamily: "avenir",
      _h1: {
        lineHeight: { _: "2.375rem", md: "3.5rem" },
        fontSize: { _: "2rem", md: "2.625rem" },
        fontWeight: 700,
        color: "black",
        fontFamily: "avenir"
      },
      _h2: {
        fontSize: { _: "1.5rem", md: "2rem" },
        lineHeight: { _: "2rem", md: "3rem" },
        fontWeight: 600,
        color: "black",
        fontFamily: "avenir"
      },
      _h3: {
        fontSize: { _: "1rem", md: "1.5rem" },
        lineHeight: { _: "1.1875rem", md: "2rem" },
        fontWeight: 600,
        color: "black",
        fontFamily: "avenir"
      },
      _h4: {
        fontFamily: "avenir"
      },
      _h5: {
        fontFamily: "avenir"
      },
      _h6: {
        fontFamily: "avenir"
      },
      _p: {
        color: "black",
        fontFamily: "avenir"
      },
      _lead: {
        fontFamily: "avenir"
      }
    },
    variants: {
      prose: {
        _h1: {
          marginBottom: ".8rem",
          marginTop: "3.5rem",
          textAlign: "justify"
        },
        _h2: {
          marginBottom: ".8rem",
          marginTop: "3rem",
          textAlign: "justify"
        },
        _h3: {
          marginBottom: ".6rem",
          marginTop: "2.5rem",
          textAlign: "justify"
        },
        _h4: {
          marginBottom: ".5rem",
          marginTop: "1.5rem",
          textAlign: "justify"
        },
        _h5: {
          marginBottom: ".5rem",
          marginTop: "1rem",
          textAlign: "justify"
        },
        _h6: {
          marginBottom: ".5rem",
          marginTop: ".5rem",
          textAlign: "justify"
        },
        _p: {
          marginBottom: "1rem",
          textAlign: "justify"
        }
      }
    }
  }),
  ...tagTheme({
  }),
  ...buttonTheme({
    baseStyle: {
      borderRadius: 0,
      fontWeight: 700,
      border: 0,
      fontFamily: "avenir"
    },
    variants: {
      solid: {
        bg: { _: "robe", hover: "robeHover", active: "robeHover", focus: "robeHover" },
        color: "white",
        boxShadow: {
          _: "0px 1rem 2.5rem -0.75rem rgb(224, 0, 52)",
          hover: "0px 2rem 2.5rem -0.75rem rgb(224, 0, 52)"
        },
        _leftIcon: {
          fill: "#fff"
        }
      },
      outline: {
        bg: { _: "transparent" },
        color: { _: "gray", hover: "black", active: "black", focus: "black" },
        border: "2px solid",
        borderColor: { _: "gray", hover: "black", active: "black", focus: "black" }
      }
    },
    sizes: {
      sm: {
        px: "1.5rem",
        py: "0.75rem",
        fontSize: "1rem",
        lineHeight: "1.5rem"
      },
      md: {
        py: "0.75rem",
        px: "2rem",
        maxHeight: "3rem"
      }
    }
  }),
  ...textLinkTheme({
    baseStyle: {
      color: { _: "lightGray", hover: "white" },
      fontFamily: "avenir"
    },
    variants: {
      normal: {
        fontSize: "0.875rem",
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: "1.3125rem"
      },
      underlined: {
        color: "robe",
        borderBottom: { _: "1px solid", hover: "1px solid transparent" }
      }
    }
  }),
  ...cardTheme({
    variants: {
      elevated: {
        borderRadius: 0,
        boxShadow: {
          _: "0 0.5rem 1rem 0 rgba(30, 30, 30, 0.1), 0 1rem 2rem 0 rgba(30, 30, 30, 0.1)",
          hover: "0 1rem 2rem 0 rgba(30, 30, 30, 0.5)"
        },
        transition: "box-shadow 300ms, background 300ms"
      },
      outlined: {
        borderColor: { _: "rgba(147, 149, 152, 0.5)", hover: "rgba(147, 149, 152, 1)" },
        transition: "box-shadow 300ms, background 300ms, border-color 300ms"
      }
    }
  }),
  ...containerTheme({
    baseStyle: {
      px: "1rem"
    },
    variants: {
      normal: {
        px: { _: "1rem", sm: "1rem" }
      },
      fluid: {
        px: { _: "1rem", sm: "1rem" }
      }
    }
  }),
  ...inputTheme({
    baseStyle: {
      background: "#fff",
      borderRadius: 0
    },
    variants: {
      outline: {
        borderColor: { _: "gray", hover: "black", focusWithin: "black" },
        borderRadius: 0,
        color: { _: "black", placeholder: "gray" },
        fontFamily: "avenir"
      }
    },
    sizes: {
      md: {
        py: "0.8125rem",
        px: "1rem",
        lineHeight: "1.5rem"
      }
    }
  }),
  ...listTheme({
    baseStyle: {
      listStylePosition: "inside",
      color: "black",
      fontFamily: "avenir",
      _item: {
        listStyleType: "square",
        color: { marker: "robe" },
        mr: { marker: "0.5625rem" }
      }
    }
  }),
  ...modalTheme({
    baseStyle: {
      overflow: "auto",
      w: "100%",
      borderRadius: 0,
      _overlay: {
        zIndex: 1040,
        bg: "rgba(30, 30, 30, 0.75)"
      },
      _close: {
        w: "2rem",
        h: "2rem",
        _icon: {
          w: "2rem",
          cursor: "pointer"
        }
      }
    },
    sizes: {
      sm: {
        maxWidth: "26rem"
      },
      md: {
        maxWidth: "30rem"
      }
    }
  }),
  ...dropMenuTheme({
    baseStyle: {
      _button: {},
      _item: {
        mt: "0.75rem",
        py: 0,
        px: "1rem",
        bg: "#fff",
        maxWidth: "100%",
        cursor: "pointer",
        color: { _: "black", hover: "robe" },
        fontFamily: "avenir",
        transition: "color 300ms"
      },
      _list: {
        mt: 0,
        pt: "0.4rem",
        pb: "1rem",
        borderRadius: 0,
        bg: "#fff",
        boxShadow: "0 0.5rem 1rem 0 rgba(30, 30, 30, 0.1), 0 1rem 2rem 0 rgba(30, 30, 30, 0.1)",
        w: "16rem"
      }
    }
  }),
  ...tagTheme({
    baseStyle: {
      borderRadius: 0,
      border: 0,
      px: "0.5rem",
      py: "0.25rem",
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1.3125rem",
      fontFamily: "avenir"
    }
  })
});
