/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../src";
import { ApiChangePaymentMethodRequest, ApiCartModel } from "../../types";

/**
* send **PUT** request to **\/Ecommerce/cart/payment**
*/

export const changePaymentMethodRequest = (ctx: RequestContext, body: ApiChangePaymentMethodRequest) => request<ApiCartModel>({
  method: "PUT",
  path: encodeUri`/ecommerce/cart/payment`,
  body,
  ctx
});

/**
* send **DELETE** request to **\/Ecommerce/cart/payment**
*/

export const removePaymentFromCartRequest = (ctx: RequestContext) => request<ApiCartModel>({
  method: "DELETE",
  path: encodeUri`/ecommerce/cart/payment`,
  ctx
});
