/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../src";
import { ApiCartItemQuantityRequest, ApiCartModel } from "../../types";

/**
* send **PUT** request to **\/Ecommerce/cart/item**
*/

export const updateQuantityRequest = (ctx: RequestContext, body: ApiCartItemQuantityRequest) => request<ApiCartModel>({
  method: "PUT",
  path: encodeUri`/ecommerce/cart/item`,
  body,
  ctx
});

/**
* send **POST** request to **\/Ecommerce/cart/item**
*/

export const addToCartRequest = (ctx: RequestContext, body: ApiCartItemQuantityRequest) => request<ApiCartModel>({
  method: "POST",
  path: encodeUri`/ecommerce/cart/item`,
  body,
  ctx
});
