/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../../src";
import { ApiCartModel } from "../../../types";

/**
* send **GET** request to **\/Ecommerce/cart/credit/{useCredit}**
*/

export const useCustomerCreditRequest = (ctx: RequestContext, useCredit: boolean) => request<ApiCartModel>({
  method: "GET",
  path: encodeUri`/ecommerce/cart/credit/${useCredit}`,
  ctx
});
