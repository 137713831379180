/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";
import { ApiResetPasswordRequest } from "../../types";

/**
* send **POST** request to **\/Ecommerce/auth/reset-password**
*/

export const resetPasswordRequest = (ctx: {} = {}, body: ApiResetPasswordRequest) => request<string>({
  method: "POST",
  path: encodeUri`/ecommerce/auth/reset-password`,
  body,
  ctx
});
