/* eslint-disable max-len */
import { encodeUri, request } from "../../src";
import { ApiTaxRate } from "../types";

/**
* send **GET** request to **\/ecommerce/tax_rate**
*/

export const listTaxRatesRequest = (ctx: {} = {}) => request<ApiTaxRate[]>({
  method: "GET",
  path: encodeUri`/ecommerce/tax_rate`,
  ctx
});

/**
* send **POST** request to **\/ecommerce/tax_rate**
*/

export const upsertTaxRateRequest = (ctx: {} = {}, body: ApiTaxRate) => request<ApiTaxRate>({
  method: "POST",
  path: encodeUri`/ecommerce/tax_rate`,
  body,
  ctx
});
