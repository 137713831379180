import { x } from "@xstyled/emotion";
import { Button, Modal } from "anolis-ui";
import RobeHeading from "components/ui/RobeHeading";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  error: any;
}

export const PanicModal: FC<Props> = ({ error }) => {
  return (
    <Modal
      header={(
        <RobeHeading t="h2" fontWeight={700}>
          <FormattedMessage id="panicModal.title" defaultMessage="Unexpected error" />
        </RobeHeading>
      )}
      _close={{ display: "none" }}
      persistent
      spaceY="1rem"
    >
      <x.p textAlign="center">
        <FormattedMessage
          id="panicModal.text"
          defaultMessage="Unxpected error occured and application needs to be reloaded. We apologise for inconvenience."
        />
      </x.p>

      {error && (
        <x.pre fontSize="12px" overflow="scroll">
          {JSON.stringify({ error }, null, 2)}
        </x.pre>
      )}

      <x.div display="flex" justifyContent="center">
        <Button onClick={() => window.location.reload()}>
          <FormattedMessage
            id="panicModal.button"
            defaultMessage="Reload"
          />
        </Button>
      </x.div>
    </Modal>
  );
};
