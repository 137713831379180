import "public/static/Avenir.css";

import { AnolisProvider } from "anolis-ui";
import messages_en from "compiled-lang/en.json";
import messages_de from "compiled-lang/de.json";
import messages_ru from "compiled-lang/ru.json";
import { MenuProvider } from "components/context/MenuContext";
import CookieBar from "components/cookiebar/CookieBar";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { FC, Fragment, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { theme } from "theme";
import { xstyledTheme } from "theme/xstyled";
import TagManager from "react-gtm-module";

import { AppContextProvider } from "../components/context/AppContext";
import { SharedProps } from "../utils/ssgProps";

const App: FC<{ Component: FC<any>; pageProps: SharedProps }> = ({ Component, pageProps }) => {
  const { locale } = useRouter();

  // const messages = { // TODO: uncomment for other locales
  //   en: messages_en,
  //   de: messages_de,
  //   ru: messages_ru
  // };
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-MQMC6S6" });
  }, []);

  // const messages = { // TODO: uncomment for other locales
  //   en: messages_en,
  //   de: messages_de,
  //   ru: messages_ru
  // };

  return (
    <Fragment>
      <Head>
        <title key="title">ROBE merch</title>
        <meta property="og:title" content="ROBE merch" key="og_title" />
        <meta
          name="description"
          content="Robe is among the world’s leading moving light manufacturers, recognised for its innovation, quality engineering and dedication to the very highest production values."
          key="description"
        />
        <meta
          property="og:description"
          content="Robe is among the world’s leading moving light manufacturers, recognised for its innovation, quality engineering and dedication to the very highest production values."
          key="og_description"
        />
        <meta property="og:image" content="/static/og-image.png" key="og_image" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://robemerch.com" key="og_url" />
        <meta property="og:site_name" content="ROBE merch" />
        <meta property="og:locale" content="en_EN" />
        <meta property="fb:app_id" content="598422404039184" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Robelighting" />
        <meta name="theme-color" content="#ee0037" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"
        />
        <link rel="icon" href="/static/favicon/apple-touch-icon.png" type="image/x-icon" />
        <link rel="shortcut icon" href="/static/favicon/apple-touch-icon.png" type="image/x-icon" />
        <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/static/favicon/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#e00034" />
        <meta name="theme-color" content="#e00034" />
      </Head>

      {/* TODO: uncomment for other locales
      <IntlProvider locale={locale ?? "en"} defaultLocale="en" messages={messages[locale as "en" ?? "en"]}> */}
      {/* TODO: delete next line for other locales */}
      <IntlProvider locale={locale ?? "en"} defaultLocale="en" messages={messages_en}>
        <AnolisProvider theme={theme} xstyledTheme={xstyledTheme}>
          <AppContextProvider {...pageProps}>
            <AnolisProvider theme={theme} xstyledTheme={xstyledTheme}>
              <MenuProvider menu={pageProps._shared.menu}>
                <Component {...pageProps} />
                <CookieBar />
                <div id="portal-window" />
              </MenuProvider>
            </AnolisProvider>
          </AppContextProvider>
        </AnolisProvider>
      </IntlProvider>
    </Fragment>
  );
};

export default App;
