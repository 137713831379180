/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";
import { ApiOrderModel } from "../../types";

export interface GetUserOrdersOptions {
  page?: number;
  perPage?: number;
}

/**
* send **GET** request to **\/Ecommerce/user/orders**
*/

export const getUserOrdersRequest = (ctx: {} = {}, options: GetUserOrdersOptions = {}) => request<ApiOrderModel[]>({
  method: "GET",
  path: encodeUri`/ecommerce/user/orders`,
  ctx
}, {
  page: options.page,
  perPage: options.perPage
});
