/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";
import { ApiCustomerAddress } from "../../types";

/**
* send **POST** request to **\/Ecommerce/user/customer-addresses**
*/

export const upsertAddressRequest = (ctx: {} = {}, body: ApiCustomerAddress) => request<ApiCustomerAddress>({
  method: "POST",
  path: encodeUri`/ecommerce/user/customer-addresses`,
  body,
  ctx
});
