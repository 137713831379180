/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";
import { ApiChangePasswordRequest } from "../../types";

/**
* send **POST** request to **\/Ecommerce/user/change-password**
*/

export const changePasswordRequest = (ctx: {} = {}, body: ApiChangePasswordRequest) => request<string>({
  method: "POST",
  path: encodeUri`/ecommerce/user/change-password`,
  body,
  ctx
});
