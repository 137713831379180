import { Theme } from "@xstyled/system";
import { defaultTheme as anolisTheme } from "anolis-ui";

export const xstyledTheme: Theme = {
  states: {
    ...anolisTheme.states,
    groupHover: "[data-group]:hover &",
    marker: "&::marker"
  },
  fonts: {
    ...anolisTheme.fonts,
    avenir: "'Avenir', sans-serif"
  },
  colors: {
    ...anolisTheme.colors,
    robe: "#e00034",
    robeHover: "#d30031",
    black: "#1e1e1e",
    gray: "#939598",
    white: "#fff",
    lightGray: "#c7cace",
    darkGray: "#5e6062",
    solidWhite: "#dee1e5",
    success: "#1ab06a",
    warning: "#cd9b1d",
    darkWhite: "#eeeff1"
  }
};
