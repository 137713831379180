import { ElementType, FC } from "react";
import { Txt } from "anolis-ui";
import styled, { SystemProps as XStyledSystemProps } from "@xstyled/emotion";

type TxtProps =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "lead";

interface RobeHeadingProps extends XStyledSystemProps {
  t?: TxtProps;
  as?: ElementType;
  afterContent?: string;
}

const RobeHeading: FC<RobeHeadingProps> = (
  {
    children,
    t = "h1",
    as,
    afterContent = ".",
    ...props
  }
) => {
  return (
    <RobeHeadingStyle afterContent={afterContent}>
      <Txt t={t} as={as} textAlign="center" className={`_anolis-as-${t}`} {...props}>
        {children}
      </Txt>
    </RobeHeadingStyle>
  );
};

export default RobeHeading;

const RobeHeadingStyle = styled.div<{ afterContent?: string }>`
  & > * {
    &:after {
      content: "${props => props.afterContent}";
      color: robe;
    }
  }
`;
