/* eslint-disable max-len */
import { encodeUri, request } from "../../src";
import { ApiCountry } from "../types";

/**
* send **GET** request to **\/Ecommerce/country**
*/

export const listCountriesRequest = (ctx: {} = {}) => request<ApiCountry[]>({
  method: "GET",
  path: encodeUri`/ecommerce/country`,
  ctx
});

/**
* send **POST** request to **\/Ecommerce/country**
*/

export const createOrUpdateCountryRequest = (ctx: {} = {}, body: ApiCountry) => request<ApiCountry>({
  method: "POST",
  path: encodeUri`/ecommerce/country`,
  body,
  ctx
});
