import { useCallback, useLayoutEffect, useRef } from "react";

// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
// https://github.com/reactjs/rfcs/pull/220
export const useEvent = <TParams extends any[], T>(fn: (...x: TParams) => T) => {
  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const ref = useRef<((...x: TParams) => T) | null>(null);

  useLayoutEffect(() => void (ref.current = fn));

  return useCallback((...args: TParams) => ref.current!(...args), []);
};
