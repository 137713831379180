import { joinPath } from "@ecommerce/core/src/utils/joinPath";

const defaultImageUrl = process.env.NEXT_PUBLIC_IMAGE_BASE_URL;

interface ImageModel {
  id: number;
  hash: string;
}

type ImgSize =
  | readonly [width: number, height: number, extension: string]
  | readonly [width: number, height: number, extension: string, multiplier: number];

export const BIG_PRODUCT_SIZE = [640, 592, "jpg"] as const;
export const CAROUSEL_PRODUCT_SIZE = [128, 128, "jpg"] as const;
export const CARD_PRODUCT_SIZE = [272, 220, "jpg"] as const;
export const CART_PRODUCT_SIZE = [64, 64, "jpg"] as const;
export const FULL_PRODUCT_SIZE = [2560, 2368, "jpg"] as const;

export const getFileUrl = (imageId: number | ImageModel): string => {
  if (!defaultImageUrl) {
    throw Error("Default image URL not defined!");
  }

  return joinPath(defaultImageUrl, "ecommerce", "files", typeof imageId !== "number" ? `${imageId.id}` : `${imageId}`);
};

export const getImageUrl = (img: ImageModel, [width, height, extension, multiplier = 1]: ImgSize) => {
  if (!defaultImageUrl) {
    throw Error("Default image URL not defined!");
  }

  return joinPath(defaultImageUrl, "ecommerce", "images", `${img.id}`, `${width}x${height}@${multiplier}-${img.hash}.${extension}`);
};
