/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../../src";
import { ApiCartModel } from "../../../types";

/**
* send **DELETE** request to **\/Ecommerce/cart/item/{productVariantId}**
*/

export const removeFromCartRequest = (ctx: RequestContext, productVariantId: number) => request<ApiCartModel>({
  method: "DELETE",
  path: encodeUri`/ecommerce/cart/item/${productVariantId}`,
  ctx
});
