import { ApiNodeOfCategoryDtoAndInteger } from "@ecommerce/core/generated";
import { createContext, ReactNode, useState } from "react";

type MenuProviderProps = {
  children: ReactNode;
  menu: ApiNodeOfCategoryDtoAndInteger[];
};

type MenuContextProps = {
  menu: ApiNodeOfCategoryDtoAndInteger[];
  cartMenuOpen: boolean;
  setCartMenuOpen: (status: boolean) => void;
};

export const MenuContext = createContext<MenuContextProps | undefined>(undefined);

export const MenuProvider = ({ children, menu }: MenuProviderProps) => {
  const [cartMenuOpen, setCartMenuOpen] = useState<boolean>(false);
  return (
    <MenuContext.Provider value={{ menu, cartMenuOpen, setCartMenuOpen }}>
      {children}
    </MenuContext.Provider>
  );
};
