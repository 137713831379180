/* eslint-disable max-len */
import { encodeUri, request } from "../../../../../src";
import { ApiOrderDetailModel } from "../../../../types";

/**
* send **GET** request to **\/Ecommerce/user/orders/{orderId}/detail**
*/

export const getUserOrderDetailRequest = (ctx: {} = {}, orderId: number) => request<ApiOrderDetailModel>({
  method: "GET",
  path: encodeUri`/ecommerce/user/orders/${orderId}/detail`,
  ctx
});
