/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../../src";
import { ApiCartModel } from "../../../types";

/**
* send **PUT** request to **\/Ecommerce/sale/cart/remove**
*/

export const removeSaleFromCartRequest = (ctx: RequestContext) => request<ApiCartModel>({
  method: "PUT",
  path: encodeUri`/ecommerce/sale/cart/remove`,
  ctx
});
