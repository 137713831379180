/* eslint-disable max-len */
import { encodeUri, request } from "../../../src";
import { ApiCountry } from "../../types";

/**
* send **GET** request to **\/Ecommerce/country/{code}**
*/

export const getCountryRequest = (ctx: {} = {}, code: string) => request<ApiCountry>({
  method: "GET",
  path: encodeUri`/ecommerce/country/${code}`,
  ctx
});
