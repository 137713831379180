/* eslint-disable max-len */
import { encodeUri, request } from "../../src";
import { ApiUserCustomerModel } from "../types";

/**
* send **GET** request to **\/Ecommerce/user**
*/

export const getCurrentUserRequest = (ctx: {} = {}) => request<ApiUserCustomerModel>({
  method: "GET",
  path: encodeUri`/ecommerce/user`,
  ctx
});

/**
* send **DELETE** request to **\/Ecommerce/user**
*/

export const deleteAccountRequest = (ctx: {} = {}) => request<string>({
  method: "DELETE",
  path: encodeUri`/ecommerce/user`,
  ctx
});
