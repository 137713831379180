/* eslint-disable max-len */
import { encodeUri, request, makeQuery } from "../../../src";

export interface CancelShipmentOptions {
  trackingNumber: number;
}

/**
* send **GET** request to **\/api/shipment/cancel**
*/

export const cancelShipmentRequest = (ctx: {} = {}, options: CancelShipmentOptions) => request<unknown>({
  method: "GET",
  path: encodeUri`/api/shipment/cancel?` + makeQuery("trackingNumber")(options.trackingNumber),
  ctx
});
