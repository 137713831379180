/* eslint-disable max-len */
import { encodeUri, request, RequestContext } from "../../../src";
import { ApiUserLoginRequest, ApiUserCustomerModel } from "../../types";

/**
* send **POST** request to **\/Ecommerce/auth/login**
*/

export const loginUserRequest = (ctx: RequestContext, body: ApiUserLoginRequest) => request<ApiUserCustomerModel>({
  method: "POST",
  path: encodeUri`/ecommerce/auth/login`,
  body,
  ctx
});
